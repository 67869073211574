var JJNET = {};
(function($) {
	$(function() {
		initHeaderNav();
		initSideRanking();
		initFaqList();
		initPagetop();
		initFortune();
	});
})(jQuery);

function initHeaderNav() {
	var duration = 300;
	var ease = 'easeInExpo';
	var $navs = $('#header .navigations nav');
	$navs.each(function() {
		var navPosition = $(this).offset().left;
		var navWidth = $(this).width()-1;
		$(this).find('div.child').each(function(i, elm) {
			var $subNav = $(this).find('div.inner');
			var left = navPosition - $(this).parent().offset().left;
			$subNav.css({left:left, width:navWidth});
			$(this).css({overflow:'visible'});
			$subNav.data('height', $subNav.height()).css({height:0});
		});
	});
	$navs.find('li.has-child').each(function() {
		var $nav = $(this).closest('nav');
		var $subNav = $(this).find('div.inner');
		$(this)
			.on('mouseenter', function() {
				$nav.velocity('stop').velocity({marginBottom:$subNav.data('height')}, {duration:duration, easing:ease});
				$subNav.velocity('stop').velocity({height:$subNav.data('height')}, {duration:duration, easing:ease});
			})
			.on('mouseleave', function() {
				$nav.velocity('stop').velocity({marginBottom:0}, {duration:duration, easing:ease});
				$subNav.velocity('stop').velocity({height:0}, {duration:duration, easing:ease});
			});
	});
}

function initSideRanking() {
	var $container = $('#side-ranking');
	var $articles = $container.find('div.articles');
	var $categories = $container.find('div.categories div.slides');
	if(!$container.length || !$articles.length || !$categories.length) return;
	$articles
		.css({width:'auto'})
		.slick({
			asNavFor: $categories,
			arrows: false,
			draggable: false,
			easing: 'easeInOutQuart',
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 500,
			swipe: false,
			touchMove: false
		});
	$categories
		.css({padding:0})
		.slick({
			asNavFor: $articles,
			centerMode: true,
			centerPadding: 0,
			easing: 'easeInOutQuart',
			slidesToShow: 3,
			slidesToScroll: 1,
			speed: 500
		})
		.find('.item')
			.on('click', function() {
				if($(this).next().hasClass('slick-center')) $container.find('button.slick-prev').trigger('click');
				if($(this).prev().hasClass('slick-center')) $container.find('button.slick-next').trigger('click');
			});
}

function initFaqList() {
	var lists = $('.faq-list');
	if(!lists.length) return;
	lists.each(function() {
		$(this).find('.heading').on('click', function() {
			$(this).closest('li').toggleClass('active');
		});
	});
}

function initPagetop() {
	var $header = $('#header');
	var $footer = $('#footer');
	var $pagetop = $('#pagetop');
	$pagetop.on('click', function(e) {
		e.preventDefault();
		$('html').velocity('scroll', {offset:0, duration:700, easing:'easeInOutQuart'});
	});
	$(window)
		.on('scroll.pagetop', function() {
			var scroll = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
			var headerBorder = $header.outerHeight();
			var footerBorder = $footer.position().top;
			$pagetop.toggleClass('hide', scroll < headerBorder);
			$pagetop.toggleClass('stop', scroll + $(window).height() >= footerBorder);
		})
		.trigger('scroll.pagetop');
}

function initFortune() {
	var $container = $('#fortune-result');
	if(!$container.length) return;

	var $tabs = $container.find('.tabs >div >a');
	var $contents = $container.find('.tabs-content >div');
	if(!$tabs.length || !$contents.length) return;

	var hash = location.hash;
	var $tab = (hash)? $tabs.filter('[data-fortune="' + hash.replace('#', '') + '"]') : [];
	if(!$tab.length) $tab = $tabs.filter(':first');
	var $content = $contents.filter('[data-fortune-content="' + $tab.data('fortune') + '"]');

	$tab.add($content).addClass('active');
	$('html').stop().velocity('scroll', {offset:$content.offset().top - $('#header').outerHeight()-200, duration:700, easing:'easeInOutQuart'});

	$tabs.on('click', function(e) {
		e.preventDefault();
		location.href = $(this).attr('href');
		location.reload();
		return;
	});
}

function headerAdCallBack() {
var $window = $(window);
	var $html = $('html');
	var $body = $('body');
	var $headerAd;
	var date = new Date();
	var timeout = date.getTime() + 5000;
	window.headerAdTimer = setInterval(function() {
		date = new Date();
		$headerAd = $('#gsm_header');
		if(date.getTime() > timeout || $headerAd.length) {
			clearInterval(window.headerAdTimer);
			$html.addClass('gsm-header-active');
			/* ↓これはAD側で対応してほしいです（現在の値＋上ボーダー9px）+ #gsm_headerのtop:9px */
			$body.css('margin-top', Number($('body').css('margin-top').replace('px', '')) + 9);
			$headerAd.css('top', 9);
			var s, p;
			$window
				.on('scroll.headerAd', function() {
//					console.log(s, p);
					s = $window.scrollTop();
					$html.toggleClass('header-fixed', s>p);
				})
				.on('resize.headerAd', function() {
					p = $headerAd.outerHeight();
				})
				.trigger('resize.headerAd')
				.trigger('scroll.headerAd');
		}
	}, 10);
}

jQuery.extend(jQuery.easing, {
	easeInOutQuart: function (x, t, b, c, d) {
		if ((t/=d/2) < 1) return c/2*t*t*t*t + b;
		return -c/2 * ((t-=2)*t*t*t - 2) + b;
	}
});
